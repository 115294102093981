:root {
  --timer-unit-color:#83929b;
  --tile-color: #ebf17b;
  --tile-text: #2e20a6;
  --border-tile-color: #2e20a6;
  --neon-color : #da43d9;
  --neon-background : #ecd7ec;
  --footer-background : #333;
}

@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@500&family=Sarina&display=swap');

 /* GENERAL STYLES  */


 header {
  display: flex;
  justify-content: center;
  background-color: #fff;
 }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

 /* HEADER  */

 header h1 {
  padding-top: 10px;
  color: var(--neon-color);
  font-family: 'Sarina', cursive;
  font-size: 80px;
}

main {
  display: flex;
  height: 100%;
  justify-content: center;
}

footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 10vh;
  background: var(--footer-background);
}

footer p {
  color: #fff;
  margin: auto;
}

button {
  background: var(--neon-color);
  border: none;
  border-radius: 2px;
  margin-top: 2px;
  padding : 6px;
  color: #fff;
}

button:hover {
  cursor: pointer;
}

.btn-spaced {
  margin: 10px 0;
}

.btn-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.amount-btn {
  height: 2rem;
}

#calculate-button {
  margin: 10px;
}

input {
  height: 2rem;
  padding: 2px;
  text-align: center;
}

.App header {
  min-height: 15vh;
} 

.App main {
  min-height: 75vh;
}

.App footer {
  min-height: 10vh;
}

.cocktail-tiles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border: solid 10px var(--neon-color);
  border-radius: 10px;
  margin: 10px;
  background: var(--neon-background);
  margin: 30px 6rem;
}

.cocktail-tile {
  margin: 10px;
}


.cocktail-image img, .cocktail-image-selected img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.cocktail-image-selected {
  position: relative;
}


.cocktail-image-selected::after {
  content: '';
  position: absolute;
  left: 0; top: 0;
  border-radius: 10px;
  width: 100%; height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4));
  z-index: 1;
}

.logo-container {
  height: 10rem;
}

.logo-container img {
  width: 100%;
  height: 100%;
}

.cocktail-tile {
  display: flex;
  flex-direction: column;
}

.cocktails-page h2 {
  margin: 20px;
}

 /* calculation screen  */

  .ingredients-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }

 .ingredients-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
 }

 .ingredients-list div {
  text-align: left;
 }

 .cocktail-name-list {
  margin: 10px;
 }

 .ingredients-list .amount-column {
  text-align: right;
}


 /* media queries  */

@media(max-width: 790px) {
  .cocktail-tiles {
    margin: 10px 10px;
  }  
}

@media(max-width: 500px) {
  .cocktail-tiles {
    display: flex;
    flex-direction: column;
  }
}

